<template>
  <b-card no-body class="mb-1">
    <b-card-header header-tag="header" class="p-1" role="tab">
      <b-row align-v="center">
        <b-button
          v-b-toggle="id"
          variant="white"
          class="ml-3"
          style="
             {
              float: right;
            }
          "
        >
          <span class="when-open">
            <b-icon-chevron-down />
          </span>
          <span class="when-closed">
            <b-icon-chevron-right />
          </span>
        </b-button>
        <b-col>
          <b-row v-if="editable" align-v="center">
            <b-col cols="11">
              <b-form-input
                :disabled="title.SystemBlocked || blocked"
                v-model="name"
                placeholder="Name"
                @change="$emit('renamed', name)"
              />
            </b-col>
            <b-col cols="1">
              <b-icon
                scale="1.5"
                v-b-popover.hover.top="popupGeneralBlocked"
                icon="shield-lock-fill"
                v-if="generalBlocked"
              />
              <b-button
                v-else
                :disabled="title.SystemBlocked"
                :pressed.sync="blocked"
                @click="$emit('blocked', blocked)"
                variant="outline-primary"
                size="sm"
                ><b-icon
                  scale="1.4"
                  icon="lock-fill"
                  v-if="title.SystemBlocked || blocked"
                />
                <b-icon scale="1.4" icon="unlock-fill" v-else />
              </b-button>
            </b-col>
          </b-row>
          <h6 v-else class="h6 d-inline-block">{{ title.Name }}</h6>
        </b-col>
      </b-row>
    </b-card-header>
    <b-collapse :id="id" accordion="my-accordion" role="tabpanel">
      <slot></slot>
    </b-collapse>
  </b-card>
</template>

<script>
export default {
  props: {
    id: {
      required: true,
      type: String,
    },
    editable: {
      required: true,
      type: Boolean,
    },
    title: {
      required: false,
      type: Object,
    },
    general: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      name: "",
      blocked: false,
      generalBlocked: false,
    };
  },
  computed: {
    popupGeneralBlocked() {
      return {
        variant: "secondary",
        content: this.$t("general.blockedInParent"),
      };
    },
  },
  mounted() {
    this.name = this.title.Name;
    this.blocked = this.title.Blocked;
    this.generalBlocked = this.title.GeneralBlocked;
  },
};
</script>
<style lang="scss" scoped>
.rightBlock {
  float: right;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
