<template>
  <b-tab :title="$t('fields.ResourcePool.ResourcePool')" lazy>
    <b-table
      :fields="fields"
      :items="resources"
      :busy="Loading"
      outlined
      small
      selectable
      select-mode="single"
      show-empty
      :emptyText="$t('placeholders.NoInformationAdded')"
    >
      <template v-slot:table-busy>
        <simple-loader />
      </template>

      <template v-if="editMode" v-slot:cell(Name)="row">
        <b-form-input
          v-model="row.item.Name"
          @change="UpdateAllResources(row.item)"
        />
      </template>

      <template v-slot:cell(SalaryType)="row">
        <b-form-select
          v-if="editMode"
          v-model="row.item.Salary"
          @change="onSalaryTypeChange(row.item, $event)"
        >
          <b-form-select-option :value="row.item.Salary">{{
            row.item.Salary.salaryType
          }}</b-form-select-option>
          <b-form-select-option
            v-for="(salary, index) in SalaryList.filter(
              (x) => x.idSalaryType !== row.item.Salary.idSalaryType
            )"
            v-bind:key="index"
            :value="salary"
            >{{ salary.salaryType }}</b-form-select-option
          >
          <b-form-select-option
            v-if="row.item.Salary.idSalaryType != -1"
            :value="{ salaryType: 'Custom', idSalaryType: -1, Salaries: [] }"
            >Custom</b-form-select-option
          >
        </b-form-select>
        <div v-else>
          {{ row.item.Salary.salaryType }}
        </div>
      </template>

      <template v-slot:cell(Category)="row">
        <b-form-select
          v-if="editMode && row.item.Salary.salaryType != 'Custom'"
          v-model="row.item.Category"
          @change="onSalaryCategoryChange(row.item, $event)"
        >
          <b-form-select-option
            v-for="(salary, index) in row.item.Salary.Salaries"
            v-bind:key="index"
            :value="salary"
            >{{ salary.categoryName }}</b-form-select-option
          >
        </b-form-select>
        <div v-else>
          {{ row.item.Category.categoryName }}
        </div>
      </template>

      <template v-slot:cell(Years)="row">
        <b-form-select
          v-if="editMode && row.item.Salary.salaryType != 'Custom'"
          v-model="row.item.Years"
          @change="onSalaryYearChange(row.item)"
        >
          <b-form-select-option
            v-for="(seniority, index) in row.item.Category.Seniority"
            v-bind:key="index"
            :value="seniority"
            >{{ seniority ? seniority.description : "" }}</b-form-select-option
          >
        </b-form-select>
        <div v-if="!editMode && row.item.Salary.salaryType != 'Custom'">
          {{ row.item.Years ? row.item.Years.description : "" }}
        </div>
      </template>

      <template v-if="editMode" v-slot:cell(AnnualCost)="row">
        <currency-input
          v-if="row.item.Salary.salaryType == 'Custom'"
          max="9999999"
          :number="true"
          min="0"
          step="1"
          class="form-control text-left"
          v-model="row.item.AnnualCost"
          currency="EUR"
          :locale="$i18n.locale"
          @change="updateHourlyRate(row.item)"
        />
        <div v-else class="input text-left">
          <i18n-n :value="row.value" format="currency" />
        </div>
      </template>
      <template v-else v-slot:cell(AnnualCost)="row">
        <div class="input text-left">
          <i18n-n :value="row.value" format="currency" />
        </div>
      </template>
      <template v-slot:cell(HourlyRate)="row">
        <currency-input
          v-if="row.item.Salary.salaryType == 'Custom' && editMode"
          max="9999999"
          :number="true"
          min="0"
          step="1"
          class="form-control text-left"
          v-model="row.item.HourlyRate"
          currency="EUR"
          :locale="$i18n.locale"
          @change="updateAnnualCost(row.item)"
        />
        <div v-else class="input text-left">
          <i18n-n :value="row.value" format="currency" />
        </div>
      </template>

      <template v-slot:cell(ExternalRate)="row">
        <b-row v-if="editMode">
          <b-col cols="9">
            <currency-input
              class="form-control text-left leftBlock"
              v-model="row.item.ExternalRate"
              :currency="'EUR'"
              :locale="$i18n.locale"
              max="9999999"
              :number="true"
              min="0"
              step="1"
              @change="UpdateAllResources(row.item)"
            />
            <!-- <b-form-input
              class="leftBlock"
              v-model="row.item.ExternalRate"
              type="number"
              :number="true"
              :min="row.item.HourlyRate"
              step="0.01"
              max="9999999"
              @change="UpdateAllResources(row.item)"
              :formatter="priceFormatter"
            /> -->
          </b-col>
          <b-col cols="3">
            <b-button
              class="rightBlock"
              size="sm"
              @click="DeleteResourcePool(row.item)"
              variant="danger"
            >
              <b-icon-person-dash />
            </b-button>
          </b-col>
        </b-row>
        <div v-else class="input text-left">
          <i18n-n :value="row.value" format="currency" />
        </div>
      </template>

      <template v-slot:cell(TotalResourceCost)="row">
        <div class="input text-left">
          <i18n-n :value="row.value" format="currency" />
        </div>
      </template>
      <template v-slot:cell(TotalResourceExternalCost)="row">
        <div class="input text-left">
          <i18n-n :value="row.value" format="currency" />
        </div>
      </template>
      <template v-slot:cell(TotalResourceHours)="row">
        <div class="input text-left">
          <i18n-n :value="row.value" />
        </div>
      </template>
    </b-table>
    <b-button
      v-if="editMode"
      size="sm"
      @click="AddResourcePool()"
      variant="success"
    >
      <b-icon-person-plus /> {{ $t("buttons.AddResource") }}
    </b-button>
  </b-tab>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    resources: {
      required: true,
      type: Array,
    },
    editMode: {
      required: true,
      type: Boolean,
    },
    duration: {
      required: false,
      type: Number,
    },
    showTotals: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  watch: {
    editMode() {},
    SalaryList() {},
  },
  data() {
    return {
      Loading: true,
      form: {
        months: null,
        surcharge: null,
      },
    };
  },
  methods: {
    ...mapActions("ResourcePool", [
      "getSalaryTypes",
      "getSalaryCategories",
      "getSeniorityValues",
      "getSalary",
    ]),
    ...mapActions("Customer", ["getCustomerSettings"]),
    AddResourcePool() {
      var Resource = {
        Id: "Resource_" + String(Date.now()),
        Name: "",
        HourlyRate: 0,
        AnnualCost: 0,
        Years: null,
        Category: {},
        Salary: {},
        ExternalRate: 0,
      };
      // eslint-disable-next-line vue/no-mutating-props
      this.resources.push(Resource);
    },
    DeleteResourcePool(resource) {
      const index = this.resources.indexOf(resource);
      if (index > -1) {
        // eslint-disable-next-line vue/no-mutating-props
        this.resources.splice(index, 1);
      }
    },
    onSalaryTypeChange(resourceInfo, salary) {
      resourceInfo.Category = {};
      resourceInfo.AnnualCost = 0;
      resourceInfo.HourlyRate = 0;
      resourceInfo.ExternalRate = 0;
      resourceInfo.Years = null;

      this.UpdateAllResources(resourceInfo);

      if (!salary.Salaries) {
        this.getSalaryCategories({
          session: this.$session,
          api: this.$api,
          idSalary: resourceInfo.Salary.idSalaryType,
        }).then((response) => {
          salary.Salaries = response;
          this.$forceUpdate();
        });
      }
    },
    onSalaryCategoryChange(resourceInfo, category) {
      resourceInfo.AnnualCost = 0;
      resourceInfo.HourlyRate = 0;
      resourceInfo.ExternalRate = 0;
      resourceInfo.Years = null;

      this.UpdateAllResources(resourceInfo);

      if (!category.Seniority) {
        this.getSeniorityValues({
          session: this.$session,
          api: this.$api,
          idSalary: resourceInfo.Salary.idSalaryType,
          idCategory: resourceInfo.Category.idCategory,
          duration: this.duration,
        }).then((response) => {
          category.Seniority = response;
          this.$forceUpdate();
        });
      }
    },
    onSalaryYearChange(resourceInfo) {
      this.getSalary({
        session: this.$session,
        api: this.$api,
        idSalary: resourceInfo.Salary.idSalaryType,
        idCategory: resourceInfo.Category.idCategory,
        idSeniority: resourceInfo.Years.idSeniority,
      }).then((response) => {
        resourceInfo.AnnualCost = response.annualCost;
        resourceInfo.HourlyRate = response.hourlyRate;
        resourceInfo.ExternalRate = response.externalRate;
        this.UpdateAllResources(resourceInfo);
      });
    },
    updateHourlyRate(resourceInfo) {
      if (
        Number(resourceInfo.AnnualCost) === Number ||
        Number(resourceInfo.AnnualCost) > 0
      ) {
        let hourlyRate = resourceInfo.AnnualCost / 1540;
        resourceInfo.HourlyRate = parseFloat(hourlyRate.toFixed(2));
        let percentExternal =
          (resourceInfo.HourlyRate / 100) * Number(this.form.surcharge);
        resourceInfo.ExternalRate = parseFloat(
          (resourceInfo.HourlyRate + percentExternal).toFixed(2)
        );
      } else {
        resourceInfo.HourlyRate = 0;
        resourceInfo.ExternalRate = 0;
      }
      this.UpdateAllResources(resourceInfo);
    },
    updateAnnualCost(resourceInfo) {
      if (
        Number(resourceInfo.HourlyRate) === Number ||
        Number(resourceInfo.HourlyRate) > 0
      ) {
        let annualCost = resourceInfo.HourlyRate * 1540;
        resourceInfo.AnnualCost = parseFloat(annualCost.toFixed(2));
        let percentExternal =
          (resourceInfo.HourlyRate / 100) * Number(this.form.surcharge);
        resourceInfo.ExternalRate = parseFloat(
          (resourceInfo.HourlyRate + percentExternal).toFixed(2)
        );
      } else {
        resourceInfo.AnnualCost = 0;
        resourceInfo.ExternalRate = 0;
      }
      this.UpdateAllResources(resourceInfo);
    },
    UpdateAllResources(resourceInfo) {
      if (resourceInfo.ExternalRate < resourceInfo.HourlyRate) {
        resourceInfo.ExternalRate = resourceInfo.HourlyRate;
      }
      this.$emit("updateAllResources", resourceInfo);
    },
    priceFormatter(value) {
      var floatValue = parseFloat(value);
      return parseFloat(floatValue.toFixed(2));
    },
  },
  computed: {
    ...mapGetters("ResourcePool", ["SalaryList", "Error"]),
    ...mapGetters("Customer", ["customerSettings"]),
    fields() {
      var fields = [
        {
          key: "Name",
          sortable: true,
          label: this.$t("fields.ResourcePool.Resource"),
        },
        {
          key: "SalaryType",
          sortable: true,
          label: this.$t("fields.ResourcePool.SalaryType"),
        },
        {
          key: "Category",
          sortable: true,
          label: this.$t("fields.ResourcePool.Category"),
        },
        {
          key: "Years",
          sortable: true,
          label: this.$t("fields.ResourcePool.Years"),
        },
        {
          key: "AnnualCost",
          sortable: false,
          label: this.$t("fields.ResourcePool.AnnualCost"),
        },
        {
          key: "HourlyRate",
          sortable: false,
          label: this.$t("fields.ResourcePool.HourlyRate"),
        },
        {
          key: "ExternalRate",
          sortable: false,
          label: this.$t("fields.ResourcePool.ExternalRate"),
        },
      ];
      if (this.showTotals) {
        fields.push(
          {
            key: "TotalResourceCost",
            label: this.$t("fields.ResourcePool.TotalResourceCost"),
          },
          {
            key: "TotalResourceExternalCost",
            label: this.$t("fields.ResourcePool.TotalResourceExternalCost"),
          },
          {
            key: "TotalResourceHours",
            label: this.$t("fields.ResourcePool.TotalResourceHours"),
          }
        );
      }
      return fields;
    },
  },
  mounted() {
    this.getSalaryTypes({ session: this.$session, api: this.$api }).then(
      () => (this.Loading = false)
    );
    this.getCustomerSettings({
      session: this.$session,
      api: this.$api,
    })
      .then(() => {
        this.form = { ...this.customerSettings };
      })
      .finally(this.unbusyModal);
  },
};
</script>

<style lang="scss" scoped>
.rightBlock {
  float: right;
}
.leftBlock {
  float: left;
}
</style>
