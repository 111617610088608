<template>
  <b-btn
    variant="dark"
    class="mx-auto"
    v-b-tooltip.hover
    size="sm"
    :title="$t('views.master-form.buttons.addMaterialCostItem')"
    v-b-modal="id"
    id="add-material-cost-master-form-button"
  >
    <b-icon-link45deg />
    {{ $t("views.master-form.buttons.addMaterialCostItem") }}
    <b-modal
      :id="id"
      ref="add-material-cost-master-form-modal"
      :title="$t('views.master-form.buttons.addMaterialCostItem')"
      centered
      scrollable
      header-bg-variant="dark"
      header-text-variant="light"
      @show="resetModal"
      size="lg"
    >
      <b-overlay scrollable :show="busy">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :label="$t('administrative-costs.material-cost-tab.title')"
            :invalid-feedback="
              $t('views.master-form.messages.missingMaterialCostType')
            "
            :state="state.selectedIdMaterialCostType"
          >
            <b-form-select
              v-model="values.selectedIdMaterialCostType"
              :options="materialCostsTypes"
              text-field="costTypeName"
              value-field="idMaterialCostType"
              @change="costMaterialTypeChanged"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>{{
                  $t("views.settings.PleaseSelectAnOption")
                }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <div v-if="values.selectedIdMaterialCostType">
            <b-button-toolbar class="my-2">
              <b-form inline class="ml-auto">
                <b-input
                  v-model="keyword"
                  size="sm"
                  type="search"
                  :placeholder="$t('general.search')"
                />
                <b-input-group-append>
                  <b-button
                    variant="primary"
                    v-b-tooltip.hover
                    size="sm"
                    :title="$t('general.search')"
                  >
                    <b-icon-search />
                  </b-button>
                </b-input-group-append>
              </b-form>
            </b-button-toolbar>
            <b-table
              :busy="busy"
              :fields="fields"
              :items="costItemValues"
              :per-page="perPage"
              :filter="keyword"
              :filter-ignored-fields="[
                'average',
                'idMaterialCostCategory',
                'idMaterialCostClass',
              ]"
              :current-page="currentPage"
              class="h-100"
              small
              outlined
              fixed
              selectable
              select-mode="single"
              @row-selected="onItemSelected"
              id="MaterialCostItemTable"
            >
              <template v-if="!state.selectedItem" #table-caption>
                <div class="text-danger">
                  {{ $t("views.master-form.messages.missingMaterialCostItem") }}
                </div></template
              >
              <template v-slot:cell(average)="row">
                <i18n-n :value="row.value" format="currency" />
              </template>
            </b-table>
          </div>
        </form>
        <template #overlay>
          <simple-loader />
        </template>
      </b-overlay>
      <template v-slot:modal-footer>
        <slot name="footer">
          <b-button @click="$bvModal.hide(id)" :disabled="busy">{{
            $t("general.cancel")
          }}</b-button
          ><b-button variant="primary" @click="handleOk" :disabled="busy">{{
            $t("general.ok")
          }}</b-button></slot
        >
      </template>
    </b-modal>
  </b-btn>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    costId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      busy: false,
      values: {
        selectedItem: null,
        selectedIdMaterialCostType: null,
      },
      state: {
        selectedItem: true,
        selectedIdMaterialCostType: null,
      },
      baseId: "add-material-cost-modal",
      id: "add-material-cost-modal",
      perPage: 15,
      currentPage: 1,
      keyword: null,
    };
  },
  methods: {
    ...mapActions("MaterialCosts", [
      "getMaterialCostsTypes",
      "getCostCategory",
      "getCostClasses",
      "getCostValues",
    ]),
    checkFormValidity() {
      if (!this.values.selectedIdMaterialCostType) {
        this.state.selectedIdMaterialCostType = false;
        return false;
      }

      this.state.selectedItem = this.values.selectedItem !== null;
      return this.state.selectedItem;
    },
    resetModal() {
      this.values.selectedIdMaterialCostType = null;
      this.values.selectedItem = null;

      this.keyword = null;

      this.state = {
        selectedItem: true,
        selectedIdMaterialCostType: null,
      };
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    onlyIntegers(value) {
      return Math.trunc(value);
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.$emit("onItemSelected", this.values.selectedItem);
      this.$bvModal.hide(this.id);
    },
    costMaterialTypeChanged() {
      if (this.values.selectedIdMaterialCostType != null) {
        this.state.selectedIdMaterialCostType = true;
        this.busy = true;
        this.getCostCategory({
          api: this.$api,
          idMaterialCostType: this.values.selectedIdMaterialCostType,
        })
          .then(() => {
            this.getCostValues({
              api: this.$api,
              idMaterialCostType: this.values.selectedIdMaterialCostType,
            });
          })
          .finally(() => {
            this.busy = false;
          });
      }
    },
    onItemSelected(items) {
      this.values.selectedItem = items[0];
    },
  },
  computed: {
    ...mapGetters("MaterialCosts", [
      "materialCostsTypes",
      "materialCostCategories",
      "materialCostClasses",
      "materialCostValues",
    ]),
    costItemValues() {
      return this.materialCostValues && !this.busy
        ? this.materialCostCategories.map((x) => ({
            idMaterialCostCategory: x.idMaterialCostCategory,
            name: x.categoryName,
            average: this.materialCostValues.find(
              (item) => item.idMaterialCostCategory === x.idMaterialCostCategory
            )
              ? this.materialCostValues.find(
                  (item) =>
                    item.idMaterialCostCategory === x.idMaterialCostCategory
                ).value
              : 0,
            idMaterialCostClass: this.materialCostValues.find(
              (item) => item.idMaterialCostCategory === x.idMaterialCostCategory
            )
              ? this.materialCostValues.find(
                  (item) =>
                    item.idMaterialCostCategory === x.idMaterialCostCategory
                ).idMaterialCostClass
              : null,
          }))
        : [];
    },
    fields() {
      return [
        {
          key: "name",
          sortable: true,
          label: this.$t("views.users.fields.Name"),
        },
        {
          key: "average",
          sortable: false,
          label: this.$t("Average"),
        },
      ];
    },
  },
  mounted() {
    this.id = this.baseId + this.costId;
    this.getMaterialCostsTypes({
      api: this.$api,
    });
  },
};
</script>

<style></style>
