<template>
  <fieldset class="material">
    <input
      v-bind="$attrs"
      v-bind:value="value"
      v-on:input="$emit('input', $event.target.value)"
      required
    />
    <hr />
    <label>{{ label }}</label>
  </fieldset>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>
.material {
  position: relative;
  padding: 0;
  border: none;
  overflow: visible;
}
.material input {
  width: 100%;
  padding: 12px 10px 8px;
  border-radius: 0;
  box-shadow: none;
  border: 1px solid #ddd;
  font-size: 120%;
  outline: none;
  cursor: text;
}
.material input::-webkit-input-placeholder {
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
}
.material input:not(:focus)::-webkit-input-placeholder {
  color: transparent;
}
.material hr {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 2px;
  border: none;
  background: #00aadd;
  font-size: 1px;
  will-change: transform, visibility;
  transition: all 200ms ease-out;
  transform: scaleX(0);
  visibility: hidden;
  z-index: 10;
}
.material input:focus ~ hr {
  transform: scaleX(1);
  visibility: visible;
}
.material label {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 120%;
  color: #00aadd;
  transform-origin: 0 -150%;
  transition: transform 300ms ease;
  pointer-events: none;
}
.material input:focus ~ label,
.material input:valid ~ label {
  transform: scale(0.6);
  top: 17px;
}
</style>
